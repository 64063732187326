import React, { Component, Suspense } from 'react';
const Navbar = React.lazy(() => import('../../component/Navbar/NavBar'));
const Section = React.lazy(() => import('./Section'));
const Advantages = React.lazy(() => import('../../component/Advantages'));
const Growth = React.lazy(() => import('../../component/Growth'));
const Mining = React.lazy(() => import('../../component/Mining'));
const Map = React.lazy(() => import('../../component/Map'));
const Blog = React.lazy(() => import('../../component/Blog'));
const Issues = React.lazy(() => import('../../component/Issues'));
const Footer = React.lazy(() => import('../../component/Footer/Footer'));

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navItems: [
				{ id: 1, idnm: 'home', navheading: '首页' },
				{ id: 2, idnm: 'advantages', navheading: '优势' },
				{ id: 3, idnm: 'growth', navheading: '成长历程' },
				{ id: 4, idnm: 'mining', navheading: '矿机介绍' },
				{ id: 5, idnm: 'map', navheading: '矿场分布' },
				{ id: 6, idnm: 'blog', navheading: '矿机学院' },
				{ id: 7, idnm: 'issues', navheading: '常见问题' },
			],
			pos: document.documentElement.scrollTop,
			imglight: true,
			navClass: '',
			tab: '1',
		};
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(tab) {
		this.setState({
			tab,
		});
	}

	componentDidMount() {
		window.addEventListener('scroll', this.scrollNavigation, true);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollNavigation, true);
	}

	scrollNavigation = () => {
		const scrollup = document.documentElement.scrollTop;
		if (scrollup > this.state.pos) {
			this.setState({ navClass: 'nav-sticky', imglight: false });
		} else {
			this.setState({ navClass: '', imglight: true });
		}
	};

	PreLoader = () => {
		return (
			<div id="preloader">
				<div id="status">
					<div className="spinner">
						<div className="bounce1"></div>
						<div className="bounce2"></div>
						<div className="bounce3"></div>
					</div>
				</div>
			</div>
		);
	};

	render() {
		return (
			<Suspense fallback={this.PreLoader()}>
				<Navbar
					navItems={this.state.navItems}
					navClass={this.state.navClass}
					imglight={this.state.imglight}
				/>
				<Section />
				<Advantages />

				<Growth />

				<Mining tab={this.state.tab} onChange={this.handleClick} />

				<Map />

				<Blog />

				<Issues />

				<Footer onLink={this.handleClick} />
			</Suspense>
		);
	}
}
export default Layout;
